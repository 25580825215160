
import { Component, mixins, Prop } from 'nuxt-property-decorator';
import DeviceMixin from '~/mixins/device.mixin';

@Component({
  name: 'dn-element-placeholder',
})
export default class ElementPlaceholder extends mixins(DeviceMixin) {
  @Prop({ type: Number, default: 1 }) ratio!: number;
  @Prop({ type: Number, default: 1 }) ratioMobile!: number;
  @Prop({ type: Boolean, default: false }) intersectionDisabled!: boolean;
  @Prop({ type: String, default: '' }) image!: string;

  private isIntersecting: boolean = false;

  private get imageSrc(): string {
    return this.isIntersecting ? this.image : '';
  }

  private get aspectRatio(): number {
    return this.isMobile ? this.ratioMobile : this.ratio;
  }

  private onIntersect([entry]: IntersectionObserverEntry[]) {
    if (this.isIntersecting) return;
    this.isIntersecting = entry.isIntersecting;
  }
}
